import { FunctionComponent, h, Fragment } from "preact"
import { useCallback, useMemo } from "preact/hooks"
import { useApi } from "../../../hooks/useAct"
import { getClickCoordinates, onZoneClick } from "../../../libraries/click"
import { clamp } from "../../../libraries/lodash"
import { DimmableDevice } from "../../../types/Device"
import styles from "../../styles.scss"
import Slab from "./Slab"

type IDimmerProps = {
  device: DimmableDevice
}

/** Clickable dimming zone width */
const DIMMER_WIDTH = 35
const SLAB_PADDING = 15
/** Margin applied for putting lights at 100 or 0 while having big fingers (you can't click the perfect edge of the bar) */
const MARGIN = 25

export const Dimmer: FunctionComponent<IDimmerProps> = ({ device }) => {

  const [act, loading] = useApi(device)

  const isOn = device.state

  const toggleState = useCallback(() => act(`${isOn ? "off" : "on"}`), [isOn])
  const dimTo = useCallback((percent: number) => act(percent.toString()), [])

  const clickForDim = useCallback((e: h.JSX.TargetedMouseEvent<HTMLDivElement>) => {
    const { y, height } = getClickCoordinates(e)
    const percent = clamp(Math.round((1 - ((y - MARGIN) / (height - (MARGIN * 2)))) * 100), 1, 100)
    dimTo(percent)
  }, [])

  const onActiveClick = useMemo(() => onZoneClick([[toggleState, clickForDim]], [[5, 2]]), [toggleState, clickForDim])

  return <Slab
    loading={loading}
    device={device}
    props={{
      container: {
        className: isOn ? styles.on : "",
        onClick: isOn ? onActiveClick : toggleState,
      },
      labelContainer: {
        style: {
          alignItems: "start",
        }
      }
    }}
    icon={(defaultRenderer) => <>
      <div style={{width: isOn ? `calc(100% - ${DIMMER_WIDTH - (SLAB_PADDING / 2)}px)` : "100%", textAlign: "center"}}>
        {defaultRenderer()}
      </div>
    </>}
    label={(defaultRenderer) => <>
      <div style={{width: isOn ? `calc(100% - ${DIMMER_WIDTH - (SLAB_PADDING / 2)}px)` : "100%", textAlign: "center"}}>
        {defaultRenderer()}
      </div>
    </>}
  >
    {isOn && <>
      <div style={{
        position: "absolute",
        zIndex: 1,
        right: 0,
        bottom: 0,
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        width: DIMMER_WIDTH,
      }} />
      <div style={{
        position: "absolute",
        zIndex: 2,
        right: 0,
        bottom: 0,
        height: device.brightness + "%",
        backgroundColor: "rgba(49, 208, 232, 0.2)",
        width: DIMMER_WIDTH,
      }} />
    </>}
  </Slab>
}

export default Dimmer
