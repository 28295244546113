import { DeviceItem } from "../types/Device"
import { orderBy } from "./lodash"
import { isClientSide } from "./window"

export const USE_LOCAL_DEV_SERVER = true

const IS_DEV_SERVER = /^http:\/\/(192\.168\.0\.18|localhost):(8080|5501)$/.test(location.origin)

//#region Basic internal methods
export const API_URL = IS_DEV_SERVER
  ? `http://${USE_LOCAL_DEV_SERVER ? "localhost" : "192.168.0.123"}:5501`
  : `https://smart-home.gosthome.fr`

export const _get = <Result>(endpoint: string) => !isClientSide
  ? Promise.resolve(null)
  : fetch(`${API_URL}/${endpoint}`, { credentials: "include", headers: accessTokenHeaders() })
    .then(res => res.json() as Promise<Result>)

export const _post = <Result>(endpoint: string, data: any) => !isClientSide
  ? Promise.resolve(null)
  : fetch(`${API_URL}/${endpoint}`, { credentials: "include", method: "POST", body: JSON.stringify(data), headers: { "Content-Type": "application/json", ...accessTokenHeaders() } })
    .then(res => res.json() as Promise<Result>)
//#endregion

//#region Access token & Auth
let accessToken: string | undefined
if (typeof sessionStorage !== "undefined") {
  const params = new URLSearchParams(location.search)
  if (params.has("QZXBTHZ")) {
    accessToken = "QZXBTHZ"
    sessionStorage.setItem("accessToken", accessToken)
  } else if (sessionStorage.getItem("accessToken")) {
    accessToken = sessionStorage.getItem("accessToken") || undefined
  }
}
export const setAccessToken = (token: string) => {
  accessToken = token
}
const accessTokenHeaders = (): HeadersInit => accessToken ? { "x-access-token": accessToken } : {}

type ClientSession = {
  accessToken: string
  accessTokenExpiresAt: number
  refreshToken: string
  refreshTokenExpiresAt: number
  user: {
    id: string
    email: string
    homeId: number
  }
}

let knownSession: ClientSession | undefined
const getSession = async () => {
  const session = await _get<ClientSession>(`clients/who-am-i`).catch(() => null)
  console.log({ session })

  if (session) {
    knownSession = session
  }

  return session
}

const refreshAccessToken = async () => {
  await _get(`tokens/access_token`)
    .catch(() => null)

  return getSession()
}
let firstTime = true

const ensureCorrectAccessToken = async () => {
  if (!isClientSide) {
    return
  }
  let session = knownSession || (await getSession())

  /** No user means no google Id - must authenticate */
  if (!session || !session.user) {
    alert("You must authenticate with Google (no session or no user)")
    location.href = `${API_URL}/auth/google?redirectUrl=${location.protocol}//${location.host}`
  } else if (session.refreshTokenExpiresAt < Date.now()) {
    alert("Your refresh token has expired - please authenticate again")
    location.href = `${API_URL}/auth/google?redirectUrl=${location.protocol}//${location.host}`
  } else if (session.accessTokenExpiresAt < Date.now()) {
    session = await refreshAccessToken()
  }

  if (firstTime && session) {
    console.log(`Hello ${session.user?.id || "<not-connected>"} of Home ${session.user?.homeId || "<unknown>"}`)
    console.log(`Access token expires in ${((session.accessTokenExpiresAt - Date.now()) / 1000).toFixed(0)} seconds`)
    console.log(`Refresh token expires in ${((session.refreshTokenExpiresAt - Date.now()) / (1000 * 3600 * 24)).toFixed(1)} days`)
    firstTime = false
  }

  if (session) {
    setAccessToken(session.accessToken)
  }
}

if (isClientSide) {
  ensureCorrectAccessToken()
}
//#endregion

export const get = <Result>(endpoint: string) => ensureCorrectAccessToken().then(() => _get<Result>(endpoint))

export const post = <Result>(endpoint: string, data: any) => ensureCorrectAccessToken().then(() => _post<Result>(endpoint, data))

export const getDevices = async (): Promise<DeviceItem[] | null> => {
  return get<DeviceItem[]>(`controllers/all`)
    .then((devices) => devices ? orderBy(devices, "order") : devices)
}

export const act = (device: DeviceItem, endpoint: string, body?: any) => {
  return body
    ? post(`controllers/${device.hardware}/${device.id}/${endpoint}`, body)
    : get(`controllers/${device.hardware}/${device.id}/${endpoint}`)
}

export const subtitlesApi = {
  all: () => get<Content[]>("subtitles/media"),

  searchYify: (search: string) => 
    get<YifySearchResult[]>(`subtitles/search/yify?search=${search}`),
  searchOpenSubtitles: (search: string) => 
    get<OpenSubtitlesSearchResult[]>(`subtitles/search/open-subtitles?search=${search}`),
  searchAddicted: (show: string, season: number, episode: number, languages: "eng" | "rus" | "both" = "both") => 
    get<Addic7edApiSearchResult[]>(`subtitles/search/addic7ed?show=${show}&season=${season}&episode=${episode}&languages=${languages === "both" ? "eng,rus" : languages}`),

  listYify: (content: YifySearchResult) => 
    post<YifySubtitle[]>("subtitles/search/list/yify", content),
  listOpenSubtitles: (content: OpenSubtitleListableResult, languages: "eng" | "rus" | "both" = "both") => 
    post<OpenSubtitlesSubtitle[]>(`subtitles/search/list/open-subtitles?languages=${languages === "both" ? "eng,rus" : languages}`, content),

  detailsOpenSubtitles: (content: OpenSubtitleDetailableResult, languages: "eng" | "rus" | "both" = "both") => 
    post<OpenSubtitlesSearchEpisode[]>(`subtitles/search/show-details/open-subtitles?languages=${languages === "both" ? "eng,rus" : languages}`, content),

  downloadYify: (subtitle: YifySubtitle, content: Content) => 
    post<void>("subtitles/download/yify", { subtitle, content }),
  downloadOpenSubtitles: (subtitle: OpenSubtitlesSubtitle, content: Content) => 
    post<void>("subtitles/download/open-subtitles", { subtitle, content }),
  downloadAddicted: (subtitle: Addic7edApiSearchResult, content: Content) => 
    post<boolean>("subtitles/download/addic7ed", { subtitle, content }),

  mkvTracks: (filePath: string) => 
    get<MKVSubtitleTrack[]>(`subtitles/mkv/tracks?file=${encodeURIComponent(filePath)}`),
  mkvExtractByLanguage: (filePath: string, languages: "eng" | "rus" | "both" = "both") => 
    get<{ [language: string]: MKVSubtitle[] }>(`subtitles/mkv/extract?file=${filePath}&languages=${languages === "both" ? "eng,rus" : languages}`),
  mkvExtractByTrack: (filePath: string, language: "eng" | "rus", trackNumber: number) => 
    get<{ [language: string]: MKVSubtitle[] }>(`subtitles/mkv/extract?file=${filePath}&language=${language}&trackNumber=${trackNumber}`),

  translate: (content: Content) => 
    post<Content>("subtitles/translate", content),
  dual: (content: Content) => 
    post<Content>("subtitles/dual", content),
}