import { FunctionComponent, h, Fragment } from "preact"
import { useApi } from "../../../hooks/useAct"
import { useBooleanState } from "../../../hooks/useBooleanState"
import { classnames, useEventListener } from "../../../libraries"
import { clamp, includes, round } from "../../../libraries/lodash"
import { WifiDevice } from "../../../types/Device"
import styles from "../../styles.scss"
import Button from "../../ui/Button"
import Flex from "../../ui/Flex"
import Modal from "../../ui/Modal"
import Properties from "../../ui/Properties"
import Separator from "../../ui/Separator"
import { SmallerSpan } from "../../ui/SmallerSpan"
import { Icon, Spinner } from "../Icon"
import Slab from "./Slab"

type IMotorizedScreenProps = {
  device: WifiDevice
}

type ScreenIotData = {
  opened: boolean
  parameters: {
    motor_direction: "normal" | "reverse"
    motor_speed_down: number
    motor_speed_up: number
    time_security_to_up: number
    time_to_down: number
  }
  projectorState: "on" | "off"
  state: "rolled up" | "rolled down" | "rolling up" | "rolling down"
}

const STATE_DISPLAY = {
  "rolled up": "Replié",
  "rolled down": "Déplié",
  "rolling up": "Descente...",
  "rolling down": "Montée...",
}

export const MotorizedScreen: FunctionComponent<IMotorizedScreenProps> = ({ device }) => {

  const { reachable, lastSuccessfulConnection, features, iotData } = device
  const { opened, parameters, projectorState, state } = (iotData || {}) as unknown as ScreenIotData
  const { motor_direction, motor_speed_down, motor_speed_up, time_security_to_up, time_to_down } = parameters || {}
  const directionFromString = { "normal": "0", "reverse": "1" }[motor_direction]

  const [panelIsVisible, { on: openPanel, off: hidePanel }] = useBooleanState(false)
  useEventListener("begin-inactive", hidePanel)

  const [act, loading] = useApi(device)
  const setParameters = (parameters: any) => act(`parameters`, parameters)
  const rename = (name: string) => act(`name/${name}`)
  const refresh = () => act(`refresh`)
  const reset = () => act(`reset`)

  const input = (value: number, min: number, max: number, parameter: string) => <input
    type="number"
    onChange={(e) => e.currentTarget && e.currentTarget.value && setParameters({ [parameter]: parseInt(e.currentTarget.value, 10) })}
    value={value}
    min={min}
    max={max}
    step={1}
  />

  return <Slab
    loading={loading}
    device={device}
    props={{
      container: {
        className: classnames({
          [styles.error] : !device.reachable,
          [styles.on] : state === "rolling down" || state === "rolling up",
        }),
        onClick: openPanel,
      },
    }}
    icon={() => <Flex horizontal>
      <Icon size={56} image="fan" className={styles.slabImage} />
    </Flex>}
  >
    <Modal show={panelIsVisible} onDismiss={hidePanel} modalContentProps={{style: { position: "relative" }}} title={device.name} loading={loading}>
      {panelIsVisible && <>
        <Flex style={{whiteSpace: "pre", unicodeBidi: "embed"}} verticalAlign="bottom">
          <Properties labelWidth={300} properties={{
            "Nom": <input type="text" value={device.name} onChange={(e) => e.currentTarget && e.currentTarget.value && rename(e.currentTarget.value)} />,
            "Connecté": reachable ? "Oui" : "Non",
            "Dernière communication": new Date(lastSuccessfulConnection).toLocaleString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
            "Correctement fermé": !opened ? "Oui" : "Non",
            "Vidéoprojecteur": projectorState === "on" ? "Allumé" : "Éteint",
            "État": STATE_DISPLAY[state] || `Inconnu (${state})`,
          }} />
          <Separator style={{margin: "10px 0" }} />
          
          <Properties labelWidth={300} properties={{
            "Vitesse à la descente (0-255)": input(motor_speed_down, 0, 255, "motor_speed_down"),
            "Vitesse à la remontée (0-255)": input(motor_speed_up, 0, 255, "motor_speed_up"),
            "Temps à la descente (ms)": input(time_to_down, 1000, 25000, "time_to_down"),
            "Temps à la remontée (ms)": input(time_security_to_up, 1000, 25000, "time_security_to_up"),
            "Direction": <select value={directionFromString} onChange={(e) => e.currentTarget && e.currentTarget.value && setParameters({ motor_direction: e.currentTarget.value as string })}>
              <option value="1">normal</option>
              <option value="0">reverse</option>
            </select>,
          }} />
          
          <Button onClick={refresh}>Rafraîchir</Button>
          <Button onClick={reset}>Redémarrer</Button>
        </Flex>
      </>}
    </Modal>
  </Slab>
}

export default MotorizedScreen
