import { FunctionComponent, h, Fragment } from "preact"
import { useState } from "preact/hooks"
import { useBooleanState } from "../../../hooks/useBooleanState"
import { useEventListener } from "../../../libraries"
import { round } from "../../../libraries/lodash"
import { WeatherDevice } from "../../../types/Device"
import Flex from "../../ui/Flex"
import Modal from "../../ui/Modal"
import { SmallerSpan } from "../../ui/SmallerSpan"
import { WeatherIcon } from "../WeatherIcon"
import { WeatherOverlay } from "../WeatherOverlay"
import Slab from "./Slab"

type IWeatherProps = {
  device: WeatherDevice
}

const Percentage: FunctionComponent<{ value: number, unit: string }> = ({ value, unit }) => {
  
  return <Flex horizontal>
    <span style={{ width: 35, textAlign: "right", marginRight: 5, lineHeight: 1.1 }}>{value}%</span>
    <SmallerSpan style={{ width: 30, textAlign: "left", lineHeight: 1.1 }}>{unit}</SmallerSpan>
  </Flex>
}

export const Weather: FunctionComponent<IWeatherProps> = ({ device }) => {

  const [panelIsVisible, { on: openPanel, off: hidePanel }] = useBooleanState(false)
  useEventListener("begin-inactive", hidePanel)

  const current = device?.weather?.current
  if (!current) { return null }

  const daily = device?.weather?.daily
  const today = daily[0]

  const { feels_like, humidity, clouds, dt, sunrise, sunset } = current

  return <Slab
    device={device}
    props={{
      container: {
        onClick: openPanel,
      },
      labelContainer: {
        style: {
          display: "flex",
          flexDirection: "row",
        },
      }
    }}
    content={() => <>
      <Flex>
        <span style={{fontSize: "150%", lineHeight: 1.2}}>
          {round(feels_like || 0, 1)}<SmallerSpan top>°C</SmallerSpan>
        </span>
        <Flex style={{fontSize: "100%", lineHeight: 1.1}} horizontal>
          <SmallerSpan style={{marginRight: 10}}>{round(today.temp.morn || 0, 1)}<SmallerSpan top>°C</SmallerSpan></SmallerSpan>
          <SmallerSpan>{round(today.temp.max || 0, 1)}<SmallerSpan top>°C</SmallerSpan></SmallerSpan>
        </Flex>
      </Flex>
      <Flex horizontal style={{marginTop: 5, flexGrow: 1, overflow: "auto"}}>
        <Flex style={{fontSize: "70%"}}>
          <Percentage value={clouds} unit="nua." />
          <Percentage value={humidity} unit="hum." />
          <Percentage value={Math.round(today.pop * 100)} unit="ch.pl." />
        </Flex>
        <Flex style={{ marginLeft: 5, flexGrow: 1 }}>
          <WeatherIcon dt={dt} style={{fontSize: 42}} sunset={sunset} sunrise={sunrise} id={current.weather[0].id} />
        </Flex>
      </Flex>
      <SmallerSpan
        percentage={70}
        style={{
          lineHeight: 1,
          fontStyle: "italic",
          display: "block",
          textAlign: "center",
          marginTop: 5,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {current.weather[0].description}
      </SmallerSpan>
    </>}
  >
    <Modal show={panelIsVisible} onDismiss={hidePanel}>
      {panelIsVisible && <WeatherOverlay device={device} onClick={hidePanel} full />}
    </Modal>
  </Slab>
}

export default Weather
