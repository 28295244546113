import { FunctionComponent, h } from "preact"
import { Portal } from "./Portal"
import styles from "../styles.scss"
import { classnames } from "../../libraries"
import { Spinner } from "../smart-home/Icon"

type IModalProps = {
  show: boolean
  modalProps?: h.JSX.HTMLAttributes<HTMLDivElement>
  modalContentProps?: h.JSX.HTMLAttributes<HTMLDivElement>
  modalTitleProps?: h.JSX.HTMLAttributes<HTMLDivElement>
  lightDismiss?: boolean
  onDismiss?(): void
  title?: string
  loading?: boolean
}

export const Modal: FunctionComponent<IModalProps> = ({ loading, show, modalContentProps, modalProps, modalTitleProps, children, title, lightDismiss = true, onDismiss }) => {

  const stopPropagation = (e: h.JSX.TargetedEvent<HTMLDivElement, Event>) => e.stopPropagation()

  return !show ? null : <Portal>
    <div {...modalProps} onClick={lightDismiss ? onDismiss : undefined} className={classnames(modalProps?.className, styles.modal)}>
      <div {...modalContentProps} onClick={stopPropagation} className={classnames(modalContentProps?.className, styles.modalContent)}>
        {loading && <div className={styles.loadingOverlay}>
          <Spinner />
        </div>}
        {title && <div {...modalTitleProps} className={classnames(modalTitleProps?.className, styles.modalTitle)}>{title}</div>}
        {children}
      </div>
    </div>
  </Portal>
}

export default Modal
