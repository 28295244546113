import { FunctionComponent, h } from "preact"
import { DeviceItem } from "../../../types/Device"
import styles from "../../styles.scss"
import { SmallerSpan } from "../../ui/SmallerSpan"
import Slab from "./Slab"

type IFailureProps = {
  error: string
  device: DeviceItem
}

export const Failure: FunctionComponent<IFailureProps> = ({ error, device }) => {

  return <Slab
    device={device}
    props={{}}
    content={() => <SmallerSpan>{device.hardware} - {device.id} - {device.name} - {error}</SmallerSpan>}
  />
}

export default Failure
