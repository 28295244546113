import { useEffect } from "preact/hooks"
import { isClientSide, throttle } from "../libraries"
import { API_URL } from "../libraries/api"
import { includes } from "../libraries/lodash"
import { useStateRef } from "./useStateRef"

const SOCKET_URL = API_URL.replace("http", "ws")

const createSocket = () => {
  status = "connecting"
  return new WebSocket(SOCKET_URL)
}

let socket: WebSocket
let status: "unknown" | "connecting" | "connected" | "closed" = "unknown"
let callbacks: {
  onMessage(message: any): void
  onConnected(): void
} = {
  onMessage: () => {},
  onConnected: () => {},
}

let errorCount = 0
let activeSocketHooks = 0



const makeSocket = throttle(() => {
  if (socket) {
    socket.close()
    status = "closed"
  }
  socket = createSocket()
  socket.onopen = () => {
    console.log("Socket connected")
    callbacks.onConnected()
    status = "connected"
  }
  socket.onclose = () => {
    console.log("Socket disconnected")
    const currentStatus = status
    status = "closed"
    if (activeSocketHooks > 0 && document.visibilityState === "visible" && includes(["connecting", "connected"], currentStatus)) {
      makeSocket()
    }
  }
  socket.onerror = (error) => {
    console.log("Socket error", error)
    const currentStatus = status
    status = "closed"
    errorCount++
    if (errorCount > 10) {
      location.reload()
    }
    if (document.visibilityState === "visible" && includes(["connecting", "connected"], currentStatus)) {
      makeSocket()
    }
  }
  socket.onmessage = (event) => {
    const message = JSON.parse(event.data)
    if (message.eventType === "ping") {
      socket.send(JSON.stringify({ type: "pong" }))
    } else {
      callbacks.onMessage(message)
    }
  }
}, 100)

if (isClientSide) {
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      makeSocket()
    } else {
      if (socket) {
        socket.close()
      }
    }
  })
}

const getSocketStatus = () => {
  if (!socket) {
    return "Disconnected"
  } else {
    switch (socket.readyState) {
      case WebSocket.CONNECTING:
        return "Connecting"
      case WebSocket.OPEN:
        return "Connected"
      case WebSocket.CLOSING:
        return "Closing"
      case WebSocket.CLOSED:
        return "Closed"
      default:
        return "Unknown"
    }
  }
}

export const useSocket = (onMessage: (message: any) => void, onConnect: () => void) => {

  useEffect(() => { callbacks.onMessage = onMessage }, [onMessage])
  useEffect(() => { callbacks.onConnected = onConnect }, [onConnect])

  useEffect(() => {
    activeSocketHooks++
    if (activeSocketHooks === 1 && isClientSide) {
      makeSocket()
    }

    return () => {
      activeSocketHooks--
      if (activeSocketHooks === 0 && isClientSide) {
        socket.close()
      }
    }
  }, [])

  const [socketStatus, setSocketStatus, refSocketStatus] = useStateRef("Unknown")
  useEffect(() => {
    const timer = setInterval(() => {
      const newStatus = getSocketStatus()
      setSocketStatus(newStatus)
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return socketStatus
}