import { useCallback, useEffect, useRef } from "preact/hooks"
import { emit, isClientSide } from "../libraries"
import { useIsDesktop } from "./useIsDesktop"
import { useStateRef } from "./useStateRef"

export const useActivityDetector = () => {

  const [isActive, setIsActive] = useStateRef(true)
  const isDesktop = useIsDesktop()

  const onInactivity = useCallback(() => {
    emit("begin-inactive")
    setIsActive(false)
  }, [])
  const inactivityTimer = useRef<NodeJS.Timeout>()
  const onActivity = useCallback(() => {
    if (document.visibilityState === "visible") {
      setTimeout(() => {
        setIsActive(true)
      }, 100)

      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current)
      }
      inactivityTimer.current = setTimeout(onInactivity, 15000)
    }
  }, [])

  useEffect(() => {
    if (isClientSide && !isDesktop) {
      window.addEventListener('mousemove', onActivity)
      window.addEventListener('mousedown', onActivity)
      window.addEventListener('keydown', onActivity)
      window.addEventListener('touchstart', onActivity)
      document.addEventListener("visibilitychange", onActivity)

      onActivity()

      return () => {
        window.removeEventListener('mousemove', onActivity)
        window.removeEventListener('mousedown', onActivity)
        window.removeEventListener('keydown', onActivity)
        window.removeEventListener('touchstart', onActivity)
        document.removeEventListener("visibilitychange", onActivity)
      }
    }
  }, [])

  return isActive
}
