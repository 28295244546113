import { useCallback, useState } from "preact/hooks"
import { act, _get, _post } from "../libraries"
import { DeviceItem } from "../types/Device"

export const useApi = (device: DeviceItem) => {

  const [loading, setLoading] = useState(false)

  const thisAct = useCallback((endpoint: string, body?: any) => {
    setLoading(true)
    return act(device, endpoint, body)
      .then((result) => {
        setLoading(false)
        return result
      })
  }, [device])

  const thisGet = useCallback(<Result>(endpoint: string) => {
    setLoading(true)
    return _get(endpoint)
      .then((result) => {
        setLoading(false)
        return result as Result
      })
  }, [])

  const thisPost = useCallback(<Result>(endpoint: string, data: any) => {
    setLoading(true)
    return _post(endpoint, data)
      .then((result) => {
        setLoading(false)
        return result as Result
      })
  }, [])

  return [thisAct, loading, thisGet, thisPost] as const
}