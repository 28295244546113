import { h, render, FunctionComponent, Fragment, createElement, Component } from 'preact'
import { useEffect, useRef } from 'preact/hooks'

//#region From preact/compat
type IProps = {
  _vnode: Component
  _container: Element
}

function ContextProvider(this: any, props: any) {
	this.getChildContext = () => props.context;
	return props.children;
}

function InternalPortal (this: any, props: IProps): void {
	const _this = this;
	let container = props._container;

	_this.componentWillUnmount = function() {
		render(null, _this._temp);
		_this._temp = null;
		_this._container = null;
	};

	// When we change container we should clear our old container and
	// indicate a new mount.
	if (_this._container && _this._container !== container) {
		_this.componentWillUnmount();
	}

	// When props.vnode is undefined/false/null we are dealing with some kind of
	// conditional vnode. This should not trigger a render.
	if (props._vnode) {
		if (!_this._temp) {
			_this._container = container;

			// Create a fake DOM parent node that manages a subset of `container`'s children:
			_this._temp = {
				nodeType: 1,
				parentNode: container,
				childNodes: [],
				appendChild(child: any) {
					this.childNodes.push(child);
					_this._container.appendChild(child);
				},
				insertBefore(child: any, before: any) {
					this.childNodes.push(child);
					_this._container.appendChild(child);
				},
				removeChild(child: any) {
					this.childNodes.splice(this.childNodes.indexOf(child) >>> 1, 1);
					_this._container.removeChild(child);
				}
			};
		}

		// Render our wrapping element into temp.
		render(
			createElement(ContextProvider, { context: _this.context }, props._vnode),
			_this._temp
		);
	}
	// When we come from a conditional render, on a mounted
	// portal we should clear the DOM.
	else if (_this._temp) {
		_this.componentWillUnmount();
	}
}
//#endregion

export const Portal: FunctionComponent = ({ children }) => {

  const portalContainer = useRef(document.createElement("div"))

  useEffect(() => {
    document.body.appendChild(portalContainer.current)

    return () => {
      document.body.removeChild(portalContainer.current)
    }
  }, [])

  const el = createElement(InternalPortal as any, { _vnode: <Fragment>{children}</Fragment>, _container: portalContainer.current } as any)
	;(el as any).containerInfo = portalContainer.current

	return el
}
