import { FunctionComponent, h } from "preact";

export type ISmallerSpanProps = h.JSX.HTMLAttributes<HTMLSpanElement> & {
  /** @default 75 */
  percentage?: number
  /** @default false */
  top?: boolean
}

export const SmallerSpan: FunctionComponent<ISmallerSpanProps> = ({ percentage = 75, top = false, style, children, ...props }) => (
  <span style={{fontSize: `${percentage}%`, opacity: .7, verticalAlign: top ? "text-top" : undefined, ...style as any }} {...props}>{children}</span>
)
