import { FunctionComponent, h, Fragment } from "preact"
import { useEffect, useState, useRef } from "preact/hooks"
import { padLeft } from "../../libraries/lodash"
import { WeatherDevice } from "../../types/Device"
import Flex from "../ui/Flex"
import styles from "../styles.scss"
import "../../assets/css/owfont-regular.min.css"
import { WeatherOverlay } from "./WeatherOverlay"
import { SmallerSpan } from "../ui/SmallerSpan"
import { ErrorBoundary } from "../ErrorBoundary"

type IOverlayProps = {
  weatherDevice: WeatherDevice
}

const DateTimeDisplay: FunctionComponent = () => {

  const [datetime, setDatetime] = useState(new Date())
  const interval = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        try {
          setDatetime(new Date())
        } catch (exc) {
          clearInterval(interval.current as any)
          interval.current = undefined
        }
      }, 60001 - (Date.now() % 60000))
    }

    return () => {
      try {
        if (interval.current) {
          clearInterval(interval.current as any)
          interval.current = undefined
        }
      } catch (exc) {
        // ignore
      }
    }
  })

  return (<>
    <div className={styles.time}>
      <span>{padLeft(datetime.getHours(), 2, "0")}</span>
      <SmallerSpan>{`:${padLeft(datetime.getMinutes(), 2, "0")}`}</SmallerSpan>
    </div>
    <div className={styles.date}>
      <span>{datetime.toLocaleDateString("FR-fr", { day: "numeric", month: "long", weekday: "long" })}</span>
    </div>
  </>)
}

export const Overlay: FunctionComponent<IOverlayProps> = ({ weatherDevice }) => {

  return <Flex className={styles.overlay}>
    <ErrorBoundary onFailure={(error: string) => <p style={{ color: "red" }}>{error}</p>}>
      <DateTimeDisplay />
      {weatherDevice && <WeatherOverlay device={weatherDevice} />}
    </ErrorBoundary>
  </Flex>
}

export default Overlay
