import { useCallback } from "preact/hooks"
import { DeviceItem } from "../types/Device"
import { getDevices } from "../libraries/api"
import { useStateRef } from "./useStateRef"
import { useSocket } from "./useSocket"

export const useDevices = () => {

  const [devices, setDevices, devicesRef] = useStateRef<DeviceItem[]>([])
  const updateDevice = useCallback((device: DeviceItem) => {
    const newDevices = devicesRef.current.map(d => d.id === device.id ? device : d)
    setDevices(newDevices)
  }, [])

  const onSocketMessage = useCallback((message: any) => {
    switch (message.eventType) {
      case "mqtt":
        break
      case "log":
        break
      case "deviceUpdate":
        updateDevice(message.payload)
        break
      default:
        console.log("Unknown message type", message.eventType)
    }
  }, [])

  const onSocketConnected = useCallback(() => {
    getDevices().then((devices) => setDevices(devices || []))
  }, [])

  const socketStatus = useSocket(
    onSocketMessage,
    onSocketConnected,
  )

  return { devices, socketStatus }
}
