import { compact } from "./lodash"

type Class = string | number | object | null | undefined

export const classnames = (...classes: Class[]) => {
  const output: string[] = []

  for (const c of classes) {
    if (!c) {
      continue
    }

    switch (typeof c) {
      case "string":
      case "number":
      case "bigint":
        output.push(c.toString())
        break
      case "object":
        if (c.toString === Object.prototype.toString) {
          for (const key in c) {
            if ((c as any)[key]) {
              output.push(key)
            }
          }
        } else {
          output.push(c.toString())
        }
        break
      default:
        break
    }
  }

  return compact(output).join(' ')
}