import { FunctionComponent, h } from "preact"
import { classnames } from "../../libraries"
import styles from "../styles.scss"

type IButtonProps = h.JSX.HTMLAttributes<HTMLButtonElement> & {
}

export const Button: FunctionComponent<IButtonProps> = ({ children, ...props }) => {

  return <button
    {...props}
    className={classnames(props?.className, styles.button)}
  >
    {children}
  </button>
}

export default Button
