import { FunctionComponent, h } from "preact"

export type IFlexProps = {
  horizontal?: boolean
  wrap?: boolean
  horizontalAlign?: "left" | "center" | "right" | "space-between" | "space-around" | "end"
  verticalAlign?: "top" | "center" | "bottom"
}

export const flexPropsStyle = (props: IFlexProps): h.JSX.CSSProperties => ({
  display: "flex",
  flexWrap: props.wrap ? "wrap" : "nowrap",
  flexDirection: props.horizontal ? "row" : "column",
  alignItems: props.verticalAlign || "center",
  justifyContent: props.horizontalAlign || "center",
})

export const Flex: FunctionComponent<Omit<h.JSX.HTMLAttributes<HTMLDivElement>, "wrap"> & IFlexProps> = ({ children, style, wrap, ...props }) => {

  return <div {...props} style={{
    ...flexPropsStyle({ ...props, wrap }),
    ...style as any,
  }}>
    {children}
  </div>
}

export default Flex
