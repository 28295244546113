import { FunctionComponent, h } from "preact"
import { getImage } from "../../assets/images"
import { classnames } from "../../libraries"
import styles from "../styles.scss"

export type IIconProps = Omit<h.JSX.HTMLAttributes<HTMLImageElement>, "src" | "size"> & {
  image: Parameters<typeof getImage>[0]
  size?: number
}

export const Icon: FunctionComponent<IIconProps> = ({ image, size, style, ...props }) => {
  return <img src={getImage(image)} style={{ width: size, height: size, ...style as any }} alt={image} {...props} />
}

export const Spinner: FunctionComponent<Omit<IIconProps, "image">> = ({ className, ...props }) => {
  return <Icon {...props} image="spinner" className={classnames(styles.spinner, className)} />
}
