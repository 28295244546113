import { FunctionComponent, h } from "preact"
import { useApi } from "../../../hooks/useAct"
import { StateDevice } from "../../../types/Device"
import styles from "../../styles.scss"
import Slab from "./Slab"

type IPlugProps = {
  device: StateDevice
}

export const Plug: FunctionComponent<IPlugProps> = ({ device }) => {

  const [act, loading] = useApi(device)

  const toggleState = () => act(`${device.state ? "off" : "on"}`)

  return <Slab
    loading={loading}
    disabled={(device as any).reachable === false}
    device={device}
    props={{
      container: {
        className: device.state ? styles.on : "",
        onClick: toggleState,
      },
    }}
  />
}

export default Plug
