import { ComponentChildren, FunctionComponent, h } from "preact"

type IPropertiesProps = {
  properties: { [key: string]: ComponentChildren }
  labelWidth?: number
}

export const Properties: FunctionComponent<IPropertiesProps> = ({ properties, labelWidth }) => {

  return <table>
    <tbody>
      {Object.entries(properties).map(([key, value]) => key && (
        <tr key={key}>
          <td style={{ width: labelWidth }}>
            <span style={{display: "block", margin: "0.25em", fontWeight: "bold" }}>{key}</span>
          </td>
          <td>
            <span style={{display: "block", margin: "0.25em" }}>{value}</span>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
}

export default Properties
