import { FunctionalComponent, h } from 'preact'
import { SmartHome } from './smart-home/SmartHome'
import styles from './styles.scss'
import { Link, Router } from "preact-router"

import { useIsDesktop } from '../hooks/useIsDesktop'
import AsyncRoute from 'preact-async-route'
import { classnames } from '../libraries'
import { useState } from 'preact/hooks'
import Iframe from './Iframe'

const Footer: FunctionalComponent<{ random: number }> = () => {

  const currentHref = window.location.pathname

  const links = [
    { href: "/",          label: "Smart-home" },
    { href: "/subtitles", label: "Subtitles"  },
    { href: "/sonarr",    label: "Séries"     },
    { href: "/radarr",    label: "Films"      },
    { href: "/torrent",   label: "Torrent"    },
    { href: "/octoprint", label: "Octoprint"  },
    { href: "/nas",       label: "NAS"        },
    { href: "/pm2",       label: "Logs"       },
  ]

  return <div className={styles.footer}>
    {links.map(({ href, label }) => <Link
      key={href}
      className={classnames(styles.link, { [styles.active]: currentHref === href })}
      href={href}
    >
      {label}
    </Link>)}
  </div>
}

const App: FunctionalComponent = () => {

  const desktop = useIsDesktop()

  const [random, setRandom] = useState(0)
  const updateChildren = () => setRandom(Math.random())

  return (
    <div id={styles.preact_root}>
      <Router onChange={updateChildren}>
        <SmartHome path="/" />
        <AsyncRoute path="/subtitles" getComponent={() => import('./subtitles/Subtitles').then((c) => c.Subtitles)} />
        <Iframe path="/sonarr" src="https://sonarr.gosthome.fr" />
        <Iframe path="/radarr" src="https://radarr.gosthome.fr" />
        <Iframe path="/torrent" src="https://torrent.gosthome.fr" />
        <Iframe path="/octoprint" src="https://octoprint.gosthome.fr" />
        <Iframe path="/nas" src="https://nas.gosthome.fr" />
        <Iframe path="/pm2" src="https://app.pm2.io/bucket/5f55b5a20c07583d7b311a63/backend/overview/servers" />
      </Router>
      {desktop && <Footer random={random} />}
    </div>
  )
}

export default App
