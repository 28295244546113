import { FunctionComponent, h, Fragment } from "preact"
import { useActivityDetector } from "../../hooks/useActivityDetector"
import { useDevices } from "../../hooks/useDevices"
import { WeatherDevice } from "../../types/Device"
import { Device } from "./devices"
import styles from "../styles.scss"
import Overlay from "./Overlay"
import { useIsDesktop } from "../../hooks/useIsDesktop"
import { Icon } from "./Icon"
import { useState } from "preact/hooks"
import Modal from "../ui/Modal"
import DeviceEditor from "./DeviceEditor"

const DEBUG_SOCKET_STATUS = false
const ALWAYS_OVERLAY = false

export const SmartHome: FunctionComponent = () => {

  const { devices, socketStatus } = useDevices()
  const visibleDevices = devices.filter(device => device.visible && ((device as any).reachable !== false || device.hardware === "tuya"))

  let active = useActivityDetector()
  if (ALWAYS_OVERLAY) {
    active = false
  }

  const [options, setOptions] = useState(false)

  const isDesktop = useIsDesktop()

  const weatherDevice = devices.find(device => device.id.startsWith("weather")) as WeatherDevice

  return <>
    <div className={styles.slabContainer}>
      {visibleDevices.map((device) => <Device key={device.id} device={device} />)}
    </div>
    {DEBUG_SOCKET_STATUS && <div className={styles.socketStatus}>
      {active ? "ON" : "OFF"} - {socketStatus}
    </div>}

    {isDesktop && <div className={styles.backgroundColorDarker} style={{
      borderRadius: "50%",
      padding: 15,
      position: "fixed",
      right: 25,
      bottom: 85,
    }} onClick={() => setOptions(true)}>
      <Icon style={{transform: `translateY(2px)`}} width={48} height={48} image="parameters" />
    </div>}

    {options && <Modal title="Gestion de la maison" show={options} onDismiss={() => setOptions(false)}>
      {options && <DeviceEditor devices={devices} />}
    </Modal>}
  
    {!active && weatherDevice && <Overlay weatherDevice={weatherDevice} />}
  </>
}
