import { useCallback, useRef, useState } from "preact/hooks"

export const useStateRef = <T>(initialValue: T) => {
  const [value, _setValue] = useState(initialValue)
  const ref = useRef(value)
  const setValue = useCallback((newValue: T) => {
    if (newValue !== ref.current) {
      _setValue(newValue)
      ref.current = newValue
    }
  }, [])

  return [value, setValue, ref] as const
}
