import { FunctionComponent, h, Fragment } from "preact"
import { useApi } from "../../../hooks/useAct"
import { useBooleanState } from "../../../hooks/useBooleanState"
import { classnames, useEventListener } from "../../../libraries"
import { clamp, includes, round } from "../../../libraries/lodash"
import { WifiDevice } from "../../../types/Device"
import styles from "../../styles.scss"
import Button from "../../ui/Button"
import Flex from "../../ui/Flex"
import Modal from "../../ui/Modal"
import Properties from "../../ui/Properties"
import Separator from "../../ui/Separator"
import { SmallerSpan } from "../../ui/SmallerSpan"
import { Icon, Spinner } from "../Icon"
import Slab from "./Slab"

type IFanProps = {
  device: WifiDevice
}

const pwmToPercentage = (pwm: number) => round(clamp(pwm, 0, 255) / 2.55)

enum Feature {
  sensor = "sensor",
  fan = "fan",
  plug = "plug"
}

const FEATURES_LABELS: { [feature in Feature]: string } = {
  sensor: "Capteur",
  fan: "Ventilation",
  plug: "Prise",
}

const allFeatures = Object.values(Feature)

export const Fan: FunctionComponent<IFanProps> = ({ device }) => {

  const { reachable, lastSuccessfulConnection, features, iotData } = device
  const { fanSpeed, fanControlMode, humidity, parameters, plugState, temperature } = iotData || {}
  const { humidity_max, humidity_min, pwm_max, pwm_min, temp_max, temp_min } = parameters || {}

  const [panelIsVisible, { on: openPanel, off: hidePanel }] = useBooleanState(false)
  useEventListener("begin-inactive", hidePanel)

  const [act, loading] = useApi(device)
  const setFanControlMode = (mode: string) => act(`pwm-mode/${mode}`)
  const setParameters = (parameters: any) => act(`parameters`, parameters)
  const toggleFeature = (feature: Feature) => act(`set-features`, features.indexOf(feature) === -1 ? [...features, feature] : features.filter(f => f !== feature))
  const rename = (name: string) => act(`name/${name}`)
  const refresh = () => act(`refresh`)

  const input = (value: number, min: number, max: number, parameter: string) => <input
    type="number"
    onChange={(e) => e.currentTarget && e.currentTarget.value && setParameters({ [parameter]: parseInt(e.currentTarget.value, 10) })}
    value={value}
    min={min}
    max={max}
    step={1}
  />

  return <Slab
    loading={loading}
    device={device}
    props={{
      container: {
        className: classnames({
          [styles.error] : !device.reachable,
          [styles.on] : fanSpeed && fanSpeed > 0 && device.reachable,
        }),
        onClick: openPanel,
      },
    }}
    icon={() => <Flex horizontal>
      <Icon size={56} image="fan" className={styles.slabImage} />
      <Flex style={{marginLeft: 5}}>
        {fanSpeed && <SmallerSpan style={{lineHeight: "16px"}}>{pwmToPercentage(fanSpeed)}<SmallerSpan percentage={75} top>%</SmallerSpan><Icon image="fanSpeed" size={16} style={{marginLeft: 5}} /></SmallerSpan>}
        {temperature && <SmallerSpan>{round(temperature, 1)}<SmallerSpan style={{verticalAlign: "text-bottom"}}>°C</SmallerSpan></SmallerSpan>}
        {humidity && <SmallerSpan style={{lineHeight: "16px"}}>{round(humidity)}<SmallerSpan percentage={75} top>%</SmallerSpan><Icon image="humidity" size={16} style={{marginLeft: 5}} /></SmallerSpan>}
      </Flex>
    </Flex>}
  >
    <Modal show={panelIsVisible} onDismiss={hidePanel} modalContentProps={{style: { position: "relative" }}} title={device.name} loading={loading}>
      {panelIsVisible && <>
        <Flex style={{whiteSpace: "pre", unicodeBidi: "embed"}} verticalAlign="bottom">
          <Properties labelWidth={300} properties={{
            "Nom": <input type="text" value={device.name} onChange={(e) => e.currentTarget && e.currentTarget.value && rename(e.currentTarget.value)} />,
            "Fonctionnalités": <>
              {allFeatures.map((feature) => <div>
                <input
                  id={feature}
                  type="checkbox"
                  style={{margin: "5px 5px 5px 0"}}
                  key={feature}
                  checked={features.indexOf(feature as any) >= 0}
                  onChange={(e) => toggleFeature(feature)}
                />
                <label htmlFor={feature}>{FEATURES_LABELS[feature]}</label>
              </div>)}
            </>,
            "Connecté": reachable ? "Oui" : "Non",
            "Dernière communication": new Date(lastSuccessfulConnection).toLocaleString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
          }} />
          
          {includes(features, "fan") && <>
            <Separator style={{margin: "10px 0" }} />
            <Properties labelWidth={300} properties={{
              "Vitesse ventilateur": fanSpeed && `${round(clamp(fanSpeed, 0, 255) / 2.55)}%`,
              "Mode de contrôle": 
                <select value={fanControlMode} onChange={(e) => e.currentTarget && e.currentTarget.value && setFanControlMode(e.currentTarget.value as string)}>
                  <option disabled value="invalid">Invalide</option>
                  <option value="temperature">Température</option>
                  <option value="humidity">Humidité</option>
                </select>,
            }} />
            {pwm_min !== undefined && pwm_max !== undefined && <Properties labelWidth={300} properties={{
              "Paramètres - PWM": <>
                {input(pwm_min, 0, pwm_max, "pwm_min")} - {input(pwm_max, pwm_min, 255, "pwm_max")}
              </>
            }} />}
            {fanControlMode === "humidity" && humidity_max !== undefined && humidity_min !== undefined && <Properties labelWidth={300} properties={{
              "Paramètres - Humidité": <>
                {input(humidity_min, 0, humidity_max, "humidity_min")} - {input(humidity_max, humidity_min, 100, "humidity_max")}
              </>,
            }} />}
            {fanControlMode === "temperature" && temp_max !== undefined && temp_min !== undefined && <Properties labelWidth={300} properties={{
              "Paramètres - Température": <>
                {input(temp_min, 0, temp_max, "temp_min")} - {input(temp_max, temp_min, 100, "temp_max")}
              </>,
            }} />}
          </>}
          
          {includes(features, "sensor") && <>
            <Separator style={{margin: "10px 0" }} />
            <Properties labelWidth={300} properties={{
              "Humidité": humidity && `${round(humidity)}%`,
              "Température": temperature && `${round(temperature, 1)}°C`,
            }} />
          </>}
          
          {includes(features, "plug") && <>
            <Separator style={{margin: "10px 0" }} />
            <Properties labelWidth={300} properties={{
              "Etat de la prise": plugState ? "On" : "Off",
            }} />
          </>}

          <Button onClick={refresh}>Rafraîchir</Button>
        </Flex>
      </>}
    </Modal>
  </Slab>
}

export default Fan
