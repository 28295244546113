import { DeviceItem } from "../../types/Device"

const images = {
  // "3d-printer": printer,
  // "christmas-tree": christmasTree,
  amplifier: require("./001-speakers.png"),
  projector: require("./002-projector.png"),
  bulb: require("./003-light-bulb.png"),
  screens: require("./004-desktop-computer.png"),
  "ultrawide-screen": require("./005-curved-screen.png"),
  television: require("./006-smart-tv.png"),
  "lava-lamp": require("./007-lava-lamp.png"),
  "moon-edwin": require("./008-rabbit.png"),
  shutter: require("./009-blind.png"),
  "shutter-empty": require("./009-blind-empty.png"),
  "shutter-middle": require("./009-blind-middle.png"),
  "shutter-one": require("./009-blind-one.png"),
  fan: require("./010-fan.png"),
  remote: require("./remote-control.png"),
  spinner: require("./spinner.png"),
  arrowDown: require("./down-arrow.png"),
  pause: require("./pause-button.png"),
  // coffee,
  humidity: require("./humidity.png"),
  clouds: require("./clouds.png"),
  precipitation: require("./precipitation.png"),
  fanSpeed: require("./fan-speed.png"),
  parameters: require("./parameters.png"),
  // lamp,
  // "open-bot": openBot,
  // remote,
  // tan,
  // temperature,
  // webcams,
}

export const getImage = (image: keyof typeof images) => images[image] && images[image].default as string

export const imageFromDevice = (device: Pick<DeviceItem, "icon" | "id" | "hardware">): keyof typeof images => {

  const possibilities = [device.icon, device.id, device.hardware]

  for (const possibility of possibilities) {
    const image = getImage(possibility as any)
    if (image) {
      return possibility as keyof typeof images
    }
  }

  return "bulb"
}
