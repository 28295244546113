
export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value || 0, min), max)
}

export const orderBy = <T extends any>(array: T[], key?: string): T[] => {
  return array.sort((a, b) => {
    if (key ? (a as any)[key] < (b as any)[key] : a < b) return -1
    if (key ? (a as any)[key] > (b as any)[key] : a > b) return 1
    return 0
  })
}

export const compact = (array: any[]) => {
  return Array.isArray(array) ? array.filter(Boolean) : array
}

export const includes = <T>(array: T[], value: T) => {
  return Array.isArray(array) ? array.indexOf(value) !== -1 : false
}

type ToStringable = string | number | boolean

export const padLeft = (value: ToStringable, length: number, char: string = "0") => {
  return (new Array(length + 1).join(char) + value.toString()).slice(-length)
}

export const round = (value: number, precision: number = 0) => {
  return (value || 0).toFixed(precision)
}

export const uniq = <T>(array: T[]): T[] => {
  return array.filter((value, index, self) => self.indexOf(value) === index)
}

export const ellipsis = (value: string, length: number) => {
  return value.length > length ? `${value.substring(0, length)}...` : value
}

export const fullname = (path: string) => {
  return path.split("/").pop() as string
}

export const basename = (path: string) => {
  return fullname(path).replace(/\.[^/.]+$/, "")
}

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export const revert = (string: string) => {
  return string.split("").reverse().join("")
}

export const extension = (path: string) => {
  return path.split(".").pop() as string
}

export const toCorrectUnit = (value: number, unitType: string = "") => {
  const unit = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"]
  const i = Math.floor(Math.log(value) / Math.log(1024))
  return `${(value / Math.pow(1024, i)).toFixed(1)}${unit[i]}${unitType}`
}

export const sort = <T>(array: T[], predicate: (a: T, B: T) => number) => {
  const output = array.slice()
  output.sort(predicate)
  return output
}
