import { useCallback } from "preact/hooks"
import { useStateRef } from "./useStateRef"

export const useBooleanState = (initialValue: boolean) => {
  const [value, set, ref] = useStateRef(initialValue)
  const toggle = useCallback(() => set(!ref.current), [])
  const on = useCallback(() => set(true), [])
  const off = useCallback(() => set(false), [])

  return [value, { set, toggle, on, off }] as const
}
