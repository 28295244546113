import { useEffect } from "preact/hooks"

const DEBUG = true

type EventPayloadNone = undefined
type EventPayloadOtherTest = number

type EventPayload = EventPayloadNone | EventPayloadOtherTest

//#region Internal
const subscriptions: { [key: string]: Array<(payload: any) => void> } = {}
const subscribe = (event: string, callback: (payload: any) => void) => {
  if (!subscriptions[event]) {
    subscriptions[event] = []
  }
  subscriptions[event].push(callback)
}
const unsubscribe = (event: string, callback: (payload: any) => void) => {
  if (subscriptions[event]) {
    subscriptions[event] = subscriptions[event].filter(subscription => subscription !== callback)
  }
}
//#endregion

export function emit(event: "begin-inactive"): void
export function emit(event: "other-test", payload: EventPayloadOtherTest): void
export function emit (event: string, payload?: EventPayload) {
  if (subscriptions[event]) {
    if (DEBUG) {
      console.log(`emit: ${event}. ${subscriptions[event].length} listeners`)
    }
    for (const subscription of subscriptions[event]) {
      try {
        subscription(payload)
      } catch (error) {
        console.error(error)
      }
    }
  }
}

type CallbackPayload<T extends EventPayload> = (payload: T) => void

export function useEventListener(event: "begin-inactive", callback: CallbackPayload<EventPayloadNone>): void
export function useEventListener(event: "other-test", callback: CallbackPayload<EventPayloadOtherTest>): void
export function useEventListener (event: string, callback: (payload: any) => void) {
  useEffect(() => {
    if (DEBUG) {
      console.log(`useEventListener: ${event}`)
    }
    subscribe(event, callback)
    return () => unsubscribe(event, callback)
  }, [event, callback])
}
