import { FunctionComponent, h } from "preact"
import { useEffect, useMemo, useState } from "preact/hooks"
import { useBooleanState } from "../../../hooks/useBooleanState"
import { onZoneClick } from "../../../libraries/click"
import { clamp } from "../../../libraries/lodash"
import { ShutterDevice } from "../../../types/Device"
import Flex from "../../ui/Flex"
import Grid from "../../ui/Grid"
import { Icon } from "../Icon"
import Modal from "../../ui/Modal"
import styles from "../../styles.scss"
import Slab from "./Slab"
import { useEventListener } from "../../../libraries"
import { useApi } from "../../../hooks/useAct"

type IShutterProps = {
  device: ShutterDevice
}

type IShutterIconProps = {
  active?: boolean
  /** Between 0 and 1, 0 being open */
  position: number
  /** position at which shutter touches the bottom border but is still expanded */
  firstContactRatio: number
  /** The deepest part of the shutter is often not expanded but fully compact, so only a ratio of the whole shutter is compactable. */
  compactableRatio: number

  propsContainer?: h.JSX.HTMLAttributes<HTMLDivElement>
}

const SHUTTER_ICON_DIMENSIONS = {
  height: 64,
  width: 64,
  // One moves between top -6 and top 45
  blindMinTop: -5,
  blindMaxTop: 38,
  blindSpaceExpanded: 5,
  blindSpaceCompact: 2,
  // Middle moves between top 7 (position 0) and top 55 (position firstContactRatio)
  middleMinTop: 7,
  middleMaxTop: 50,
}
const numberArrayDifferent = (a: number[], b: number[]) => a.length !== b.length || a.some((v, i) => v !== b[i])

const ShutterIcon: FunctionComponent<IShutterIconProps> = ({ active = false, position, compactableRatio, firstContactRatio, propsContainer }) => {

  const {height, width, blindMinTop, blindMaxTop, blindSpaceExpanded, blindSpaceCompact, middleMinTop, middleMaxTop} = SHUTTER_ICON_DIMENSIONS

  const middleTop = useMemo(() => Math.round(clamp((position / firstContactRatio) * (middleMaxTop - middleMinTop) + middleMinTop, middleMinTop, middleMaxTop)), [position, firstContactRatio])
  const blindExpandDistance = useMemo(() => (blindMaxTop - blindMinTop) * compactableRatio, [compactableRatio])

  const [blindsTops, setBlindsTops] = useState<number[]>([])
  useEffect(() => {
    const newBlindsTops: number[] = []
  
    if (position < firstContactRatio) {
      let currentTop = Math.round(blindMinTop + (blindMaxTop - blindMinTop) * (position / firstContactRatio))
      const maxTop = currentTop
      while (currentTop > blindMinTop) {
        newBlindsTops.push(currentTop)
        currentTop -= maxTop - currentTop < blindExpandDistance
          ? blindSpaceExpanded
          : blindSpaceCompact
      }
    } else {
      let currentTop = blindMaxTop
      const currentCompactProgress = (position - firstContactRatio) / (1 - firstContactRatio)
      const compactedTarget = compactableRatio - compactableRatio * currentCompactProgress
      while (currentTop > blindMinTop) {
        newBlindsTops.push(currentTop)
        const currentRatio = (blindMaxTop - currentTop) / (blindMaxTop - blindMinTop)
        currentTop -= currentRatio < compactedTarget
          ? blindSpaceExpanded
          : blindSpaceCompact
      }
    }
    if (numberArrayDifferent(newBlindsTops, blindsTops)) {
      setBlindsTops(newBlindsTops)
    }
  }, [position])

  return <div {...propsContainer} style={{position: "relative", height, width, ...(propsContainer?.style as h.JSX.CSSProperties) }}>
    <Icon image="shutter-empty" style={{position: "absolute", top: 0, left: 0, zIndex: 3}} />
    {blindsTops.map((top, index) => <Icon key={index} image="shutter-one" className={active ? styles.on : ""} style={{position: "absolute", top, left: 0, zIndex: 2}} />)}
    <div style={{position: "absolute", top: 0, left: 0, right: 0, overflow: "hidden", height: 55, zIndex: 1}}>
      <Icon image="shutter-middle" style={{position: "absolute", top: middleTop, height, width, maxWidth: width, maxHeight: height, left: 0}} />
    </div>
  </div>
}

const ShutterIconMultiple: FunctionComponent<IShutterIconProps> = ({ propsContainer, ...props }) => {

  const { width, height } = SHUTTER_ICON_DIMENSIONS

  return <div {...propsContainer} style={{position: "relative", overflow: "hidden", width, height, ...(propsContainer?.style as h.JSX.CSSProperties) }}>
    <ShutterIcon {...props} propsContainer={{style: { transform: "scale(.5)", position: "absolute", zIndex: 1, top: -8, left: -8, backgroundColor: "#2c2c2c" }}} />
    <ShutterIcon {...props} propsContainer={{style: { transform: "scale(.5)", position: "absolute", zIndex: 3, top: 8, left: 0, backgroundColor: "#2c2c2c" }}} />
    <ShutterIcon {...props} propsContainer={{style: { transform: "scale(.5)", position: "absolute", zIndex: 2, top: 0, left: 8, backgroundColor: "#2c2c2c" }}} />
  </div>
}


export const Shutter: FunctionComponent<IShutterProps> = ({ device }) => {

  const [act, loading, get] = useApi(device)
  const [panelIsVisible, { on: openPanel, off: hidePanel }] = useBooleanState(false)
  useEventListener("begin-inactive", hidePanel)

  const setDirection = (direction: "up" | "still" | "down") => act(direction)
  const setNamedPosition = (position: "open" | "closed" | "half") => act(`named-position/${position}`).then(hidePanel)
  const setAllNamedPosition = (position: "open" | "closed" | "half") => get(`controllers/${device.hardware}/all-shutters/named-position/${position}`).then(hidePanel)

  device.icon = "shutter"

  const arrowStyle: h.JSX.CSSProperties = {
    marginTop: "auto",
    width: 24,
    height: 24,
  }

  const up = <Icon image="arrowDown" style={{...arrowStyle, transform: `rotate(180deg)`}} className={device.position === 0 ? styles.disabled : ""} />
  const down = <Icon image="arrowDown" style={arrowStyle} className={device.position === 1 ? styles.disabled : ""} />
  const still = <Icon image="pause" style={arrowStyle} className={styles.on} />

  const inactiveClick = useMemo(() => onZoneClick([[
    () => setDirection("up"),
    () => {},
    () => setDirection("down"),
  ], [openPanel]], [], [2, 1]), [setDirection])

  const activeClick = useMemo(() => onZoneClick([[
    () => setDirection("still"),
  ]]), [setDirection])

  
  const shutterIconMultipleCommon = {
    compactableRatio: 0.8,
    firstContactRatio: 0.5,
  }
  const shutterIconSingleCommon = (position: number, namedPosition: string) => ({
    ...shutterIconMultipleCommon,
    position,
    propsContainer: { style: { transform: "scale(0.5)"}, onClick: () => setNamedPosition(namedPosition as any) },
  }) as const

  return <Slab
    loading={loading}
    disabled={(device as any).reachable === false}
    device={device}
    props={{
      container: {
        className: device.direction !== "still" ? styles.on : "",
        onClick: device.direction !== "still" ? activeClick : inactiveClick,
      },
    }}
    icon={() => <Flex horizontal style={{width: "100%"}}>
      {device.direction === "still" ? up : still}
      <ShutterIcon active={device.direction !== "still"} position={device.position} compactableRatio={device.compactableRatio} firstContactRatio={device.firstContactRatio} />
      {device.direction === "still" ? down : still}
    </Flex>}
    label={(defaultRenderer) => <Flex horizontal style={{width: "100%"}}>
      {defaultRenderer()}
    </Flex>}
  >
    <Modal show={panelIsVisible} onDismiss={hidePanel} title={device.name} loading={loading}>
      <Grid columns={3} rows={3}>
        <label>Ouvrir</label>
        <ShutterIcon {...shutterIconSingleCommon(0, "open")} />
        <ShutterIconMultiple position={0} {...shutterIconMultipleCommon} propsContainer={{onClick: () => setAllNamedPosition("open")}} />

        <label>Fermer</label>
        <ShutterIcon {...shutterIconSingleCommon(1, "closed")} />
        <ShutterIconMultiple position={1} {...shutterIconMultipleCommon} propsContainer={{onClick: () => setAllNamedPosition("closed")}} />

        <label>Pénombre</label>
        <ShutterIcon {...shutterIconSingleCommon(0.5, "half")} />
        <ShutterIconMultiple position={0.5} {...shutterIconMultipleCommon} propsContainer={{onClick: () => setAllNamedPosition("half")}} />
      </Grid>
    </Modal>
  </Slab>
}

export default Shutter
