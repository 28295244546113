import { FunctionComponent, h } from "preact"
import { DeviceItem } from "../../types/Device"
import Flex from "../ui/Flex"
import styles from "../styles.scss"
import { act, sort } from "../../libraries"
import { useStateRef } from "../../hooks/useStateRef"
import { useCallback } from "preact/hooks"

type IDeviceEditorProps = {
  devices: DeviceItem[]
}

const TH: FunctionComponent = ({ children }) => <th>
  <div className={styles.cell}>
    {children}
  </div>
</th>

const TD: FunctionComponent = ({ children }) => <td>
  <div className={styles.cell}>
    {children}
  </div>
</td>

export const DeviceEditor: FunctionComponent<IDeviceEditorProps> = ({ devices }) => {

  const orderedDevices = sort(devices, (a, b) => {
    // Sort first by visibility
    if (a.visible !== b.visible) {
      return a.visible ? -1 : 1
    }
    // Then by index
    if (a.index !== b.index) {
      return a.index - b.index
    }
    // Then by name
    return a.name.localeCompare(b.name)
  })

  const [loadingDevicesIds, setLoadingDevicesIds, loadingDevicesIdsRef] = useStateRef<string[]>([])

  const actDevice = useCallback(<Result extends any>(device: DeviceItem, endpoint: string, body?: any): Promise<Result> => {
    setLoadingDevicesIds(loadingDevicesIdsRef.current.concat(device.id))
    return act(device, endpoint, body)
      .then((result) => {
        setLoadingDevicesIds(loadingDevicesIdsRef.current.filter(id => id !== device.id))
        return result as Result
      })
  }, [])

  const setVisiblity = (device: DeviceItem, state: boolean) => {
    actDevice(device, `visible/${state ? "on" : "off"}`)
    const newIndex = state
      ? orderedDevices.filter(d => d.visible).length
      : 99 - orderedDevices.filter(d => !d.visible).length
    actDevice(device, `index/${newIndex}`)
  }

  const correctAllIndexes = () => {
    for (let i = 0; i < orderedDevices.length; i++) {
      const device = orderedDevices[i]
      let targetIndex = 1 + i
      if (!device.visible) {
        targetIndex = 99 - orderedDevices.length + i + 1
      }
      if (device.index !== targetIndex) {
        console.log(`Moving ${device.name} to index ${targetIndex} from ${device.index}`)
        actDevice(device, `index/${targetIndex}`)
      }
    }
  }

  const swap = (device: DeviceItem, destinationDevice: DeviceItem) => {
    const sourceIndex = device.index
    const destinationIndex = destinationDevice.index
    Promise.all([
      actDevice(device, `index/${destinationIndex}`),
      actDevice(destinationDevice, `index/${sourceIndex}`),
    ])
      .then(() => new Promise((resolve) => setTimeout(resolve, 500)))
      .then(correctAllIndexes)
  }

  return <Flex>
    <table>
      <thead>
        <tr>
          <TH></TH>
          <TH></TH>
          <TH>ID</TH>
          <TH>Nom</TH>
          <TH>Contrôleur</TH>
          <TH>Visible</TH>
          <TH>Ordre</TH>
        </tr>
      </thead>
      <tbody>
        {orderedDevices.map((device, index) => <tr key={device.id}>
          <td>
            {index > 0 && device.visible && <span className={styles.clickableSpan} onClick={() => swap(device, orderedDevices[index - 1])}>Up</span>}
          </td>
          <td>
            {index < orderedDevices.length - 1 && orderedDevices[index + 1].visible && <span className={styles.clickableSpan} onClick={() => swap(device, orderedDevices[index + 1])}>Down</span>}
          </td>
          <TD>{device.id}</TD>
          <TD>{device.name}</TD>
          <TD>{device.hardware}</TD>
          <TD>
            <input type="checkbox" checked={device.visible} onClick={() => setVisiblity(device, !device.visible)} />
          </TD>
          <TD>{device.index}</TD>
        </tr>)}
      </tbody>
    </table>
  </Flex>
}

export default DeviceEditor
