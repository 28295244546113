import { FunctionComponent, h, Fragment } from "preact"
import { useCallback, useMemo } from "preact/hooks"
import { useBooleanState } from "../../../hooks/useBooleanState"
import { onZoneClick } from "../../../libraries/click"
import { AmplifierDevice } from "../../../types/Device"
import Button from "../../ui/Button"
import Flex from "../../ui/Flex"
import { Icon } from "../Icon"
import Modal from "../../ui/Modal"
import styles from "../../styles.scss"
import Slab from "./Slab"
import { useEventListener } from "../../../libraries"
import { useApi } from "../../../hooks/useAct"

type IAmplifierProps = {
  device: AmplifierDevice
}

const VOLUME_BUTTONS = [
  { text: "--", command: "down5" },
  { text: "-", command: "down" },
  { text: "+", command: "up" },
  { text: "++", command: "up5" },
] as const

const SOURCE_BUTTONS = [
  {
    text: 'Souris 5.1',
    command: 'Media 5.1'
  },
  {
    text: 'Souris 2.0',
    command: 'Media 2.0'
  },
  {
    text: 'Cast',
    command: 'TV'
  },
  {
    text: 'Music',
    command: 'Music'
  },
  {
    text: 'Game',
    command: 'Aux'
  },
] as const

export const Amplifier: FunctionComponent<IAmplifierProps> = ({ device }) => {

  const [act, loading] = useApi(device)

  const toggleState = useCallback(() => act(`${device.state ? "off" : "on"}`), [device.state])
  const setVolume = useCallback((direction: "down5" | "down" | "up" | "up5") => act(`volume/${direction}`), [])
  const setSource = useCallback((source: string) => act(`mode/${source}`), [])

  const [panelIsVisible, { on: openPanel, off: hidePanel }] = useBooleanState(false)
  useEventListener("begin-inactive", hidePanel)

  const onActiveClick = useCallback(onZoneClick([[toggleState], [openPanel]], [], [2, 1]), [toggleState])

  const volumeStyles: h.JSX.CSSProperties = {
    textAlign: "center",
    width: 40,
  }

  const { label: currentSource } = useMemo(() => device.sources[device.currentSource], [device.currentSource, device.sources])

  return <Slab
    loading={loading}
    device={device}
    props={{
      container: {
        className: device.state ? styles.on : "",
        onClick: device.state ? onActiveClick : toggleState,
      },
      labelContainer: {
        style: {
          display: "flex",
          flexDirection: "row",
        },
      }
    }}
    label={(defaultRenderer) => <>
      {defaultRenderer()}
      {device.state && <div style={{
        marginLeft: 10,
        textAlign: "center",
      }}>
        <Icon
          className={styles.on}
          style={{ width: 32, height: 32 }}
          image="remote"
        />
      </div>}
    </>}
  >
    <Modal title="Ampli" show={panelIsVisible} onDismiss={hidePanel}>
      <Flex horizontalAlign="center">
        <h3>Volume</h3>
        <Flex horizontal horizontalAlign="space-between">
          {VOLUME_BUTTONS.map(({ command, text }) =>
            <Button
              key={command}
              style={volumeStyles}
              onClick={() => setVolume(command)}
            >
              {text}
            </Button>
          )}
        </Flex>
        <h3>Source</h3>
        <Flex horizontal horizontalAlign="space-between" wrap>
          {SOURCE_BUTTONS.map(({ command, text }) =>
            <Button
              key={command}
              disabled={currentSource === command}
              onClick={() => setSource(command)}
            >
              {text}
            </Button>
          )}
        </Flex>
      </Flex>
    </Modal>
  </Slab>
}

export default Amplifier
