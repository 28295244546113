import { ComponentChildren, FunctionComponent, h, Fragment } from "preact"
import { DeviceItem } from "../../../types/Device"
import styles from "../../styles.scss"
import { imageFromDevice } from "../../../assets/images"
import { Icon, IIconProps, Spinner } from "../Icon"
import { classnames } from "../../../libraries"

type ISlabProps = {
  device: Pick<DeviceItem, "name" | "id" | "icon" | "hardware">
  icon?: (defaultRenderer: () => ComponentChildren) => ComponentChildren
  label?: (defaultRenderer: () => ComponentChildren) => ComponentChildren
  content?: () => ComponentChildren
  disabled?: boolean
  props?: {
    container?: h.JSX.HTMLAttributes<HTMLDivElement>
    icon?: Partial<IIconProps>
    labelContainer?: h.JSX.HTMLAttributes<HTMLDivElement>
    label?: h.JSX.HTMLAttributes<HTMLLabelElement>
  }
  loading?: boolean
}

export const Slab: FunctionComponent<ISlabProps> = ({ device, disabled, icon, label, content, loading, props, children }) => {

  const renderIcon = () => <Icon {...props?.icon as any} image={props?.icon?.image ?? imageFromDevice(device)} className={classnames(props?.icon?.className, styles.slabImage)} />
  const renderLabel = () => <label {...props?.label} className={classnames(props?.label?.className, styles.label)}>{props?.label?.children ?? device.name}</label>

  return <div {...props?.container} className={classnames(styles.slab, props?.container?.className, { [styles.disabled]: disabled })}>
    {loading && <div className={styles.loadingOverlay}>
      <Spinner />
    </div>}
    {content
      ? content()
      : <>
        {icon
          ? icon(renderIcon)
          : renderIcon()
        }
        <div {...props?.labelContainer} className={classnames(props?.labelContainer?.className, styles.labelContainer)}>
          {label
            ? label(renderLabel)
            : renderLabel()
          }
        </div>
    </>}
    {children}
  </div>
}

export default Slab
