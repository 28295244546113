import { FunctionComponent, h } from "preact"
import Flex, { IFlexProps } from "./Flex"

type IGridProps = {
  children: h.JSX.Element[]
  columns?: number
  rows?: number
  gap?: number
  columnGap?: number
  rowGap?: number
  flexProps?: IFlexProps
}

export const Grid: FunctionComponent<h.JSX.HTMLAttributes<HTMLDivElement> & IGridProps> = ({ children, columns = 1, rows = 1, gap = 0, columnGap = 0, rowGap = 0, flexProps }) => {

  return <div
    style={{
      display: "grid",
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridTemplateRows: `repeat(${rows}, 1fr)`,
      columnGap: `${columnGap}px`,
      rowGap: `${rowGap}px`,
    }}
  >
    {children.map((child, index) => <Flex {...flexProps as any} key={index}>
      {child}
    </Flex>)}
  </div>
}

export default Grid
