import { h } from "preact"

export type ClickHandler = (e: h.JSX.TargetedMouseEvent<HTMLDivElement>) => any

export const getClickCoordinates = (e: h.JSX.TargetedMouseEvent<HTMLDivElement>) => {
  const rect = e.currentTarget.getBoundingClientRect()
  return {
    x: e.clientX - rect.left,
    y: e.clientY - rect.top,
    width: rect.width,
    height: rect.height,
  }
}

export const onZoneClick = (zones: ClickHandler[][], horizontalWeights: number[][] = [], verticalWeights: number[] = []): ClickHandler => {

  /** Example: [ [1, 2, 1] , [1, 1, 1] ] */
  horizontalWeights = horizontalWeights.length > 0 ? horizontalWeights : zones.map(zone => zone.map(() => 1))
  /** Example: [2, 1] */
  verticalWeights = verticalWeights.length > 0 ? verticalWeights : zones.map(() => 1)

  /** Which would make such a layout (zones numbers) */
  /** 
   *  1 2 2 3
   *  1 2 2 3
   *  4  5  6
   */

  return (e: h.JSX.TargetedMouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const { width, height, x, y } = getClickCoordinates(e)

    /** So we can use the weights to calculate the zone */
    const thisY = y * verticalWeights.reduce((acc, weight) => acc + weight, 0) / height

    let row = 0
    let col = 0

    let acc = 0
    for (let i = 0; i < verticalWeights.length; i++) {
      acc += verticalWeights[i]
      if (thisY < acc) {
        row = i
        break
      }
    }

    const thisXRatio = x * horizontalWeights[row].reduce((acc, weight) => acc + weight, 0) / width
    acc = 0
    for (let i = 0; i < horizontalWeights[row].length; i++) {
      acc += horizontalWeights[row][i]
      if (thisXRatio < acc) {
        col = i
        break
      }
    }

    // console.log(`Clicked on zone ${row} ${col} | ${x} ${y}`, { horizontalWeights, verticalWeights })

    zones[row][col](e)
  }
}
