import { h, Component, ComponentChildren } from 'preact'

type IErrorBoundaryProps = {
  onFailure(error: string): ComponentChildren
}

type IErrorBoundaryState = {
  error: null | string
}

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  state = { error: null }

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return { error: error.message }
  }

  componentDidCatch(error: Error) {
    console.error(error)
    this.setState({ error: error.message })
  }

  render() {
    if (this.state.error) {
      return this.props.onFailure(this.state.error)
    }
    return this.props.children
  }
}
