import { FunctionComponent, h } from "preact"

type IIframeProps = {
  src: string
}

export const Iframe: FunctionComponent<IIframeProps> = ({ src }) => {

  return <iframe
    style={{ width: "100%", height: `calc(100% - 60px)` }}
    src={src}
    frameBorder="0"
    allowFullScreen
  />
}

export default Iframe
