import { FunctionComponent, h } from "preact"
import { includes, round } from "../../libraries/lodash"
import { WeatherDevice } from "../../types/Device"
import Flex from "../ui/Flex"
import { Icon } from "./Icon"
import { SmallerSpan } from "../ui/SmallerSpan"
import styles from "../styles.scss"
import { WeatherIcon } from "./WeatherIcon"

export type IWeatherOverlayProps = {
  device: WeatherDevice
  onClick?(): void
  full?: boolean
}

export const WeatherOverlay: FunctionComponent<IWeatherOverlayProps> = ({ device, onClick, full = false }) => {

  const current = device?.weather?.current
  if (!current) { return null }
  const daily = device?.weather?.daily
  if (!daily) { return null }
  const today = daily[0]
  const hourly = device?.weather?.hourly
  if (!hourly) { return null }

  const { feels_like, humidity, sunrise, sunset, dt, clouds } = current

  const ICONS_SIZE = "2.5vw"
  const ICONS_STYLE = (last = false) => ({
    width: ICONS_SIZE,
    height: ICONS_SIZE,
    marginRight: !last ? "3vw" : undefined,
    marginLeft: "1vw",
    opacity: .6,
  })

  const HOURS_TO_SHOW = [8, 12, 14, 18]
  const hourlyToday = hourly
    .filter(({ dt }) => {
      const date = new Date(dt * 1000)
      return (full && dt * 1000 > Date.now()) || (includes(HOURS_TO_SHOW, date.getHours()) && date.getDate() === new Date().getDate())
    })

  return (
    <Flex onClick={onClick} className={styles.weatherOverlay}>
      <Flex horizontal>
        <Flex style={{marginRight: "10vw"}}>
          <Flex horizontal>
            <span style={{marginRight: "2vw", fontSize: "150%"}}>
              {round(feels_like || 0, 1)}<SmallerSpan top>°C</SmallerSpan>
            </span>
            <Flex style={{fontSize: "100%", lineHeight: 1.1}}>
              <SmallerSpan>{round(today.temp.morn || 0, 1)}<SmallerSpan top>°C</SmallerSpan></SmallerSpan>
              <SmallerSpan>{round(today.temp.max || 0, 1)}<SmallerSpan top>°C</SmallerSpan></SmallerSpan>
            </Flex>
          </Flex>
          <Flex horizontal style={{fontSize: "70%"}}>
            {clouds}%<Icon image="clouds" style={ICONS_STYLE()} />
            {humidity}%<Icon image="humidity" style={ICONS_STYLE()} />
            {Math.round(today.pop * 100)}%<Icon image="precipitation" style={ICONS_STYLE(true)} />
          </Flex>
        </Flex>

        {current.weather.map(({ id, description }, key) => <Flex key={key + 1}>
          <WeatherIcon dt={dt} style={{fontSize: "200%"}} sunset={sunset} sunrise={sunrise} id={id} />
          <span style={{lineHeight: 1, fontSize: "70%"}}>{description}</span>
        </Flex>)}
      </Flex>

      <Flex horizontal wrap>
        <Flex horizontal style={{marginTop: "2vw", marginRight: "5vw"}}>
          {!full && hourlyToday.map(({ dt, temp, weather }, key) => <Flex key={key + 1} style={{fontSize: "60%", marginRight: "3vw"}}>
            <span>{new Date(dt * 1000).getHours()}h</span>
            <WeatherIcon dt={dt} style={{fontSize: "4vw"}} sunset={sunset} sunrise={sunrise} id={weather[0].id} />
            <span>{round(temp || 0, 1)}<SmallerSpan top>°C</SmallerSpan></span>
          </Flex>)}
        </Flex>
        <Flex horizontal style={{marginTop: "2vw"}}>
          {daily.slice(1, full ? undefined : 4).map(({ temp, dt: thisDt, weather }, key) => <Flex key={key + 1} style={{fontSize: "60%", marginRight: "3vw"}}>
            <span>{new Date(thisDt * 1000).toLocaleString("FR-fr", { weekday: "short" })}</span>
            <WeatherIcon dt={dt} style={{fontSize: "4vw"}} sunset={sunset} sunrise={sunrise} id={weather[0].id} />
            <span>{round(temp.day || 0, 1)}<SmallerSpan top>°C</SmallerSpan></span>
          </Flex>)}
        </Flex>
      </Flex>

      {full && <Flex style={{fontSize: "60%"}} horizontal wrap>
        {hourlyToday.map(({ dt, temp, weather }, key) => <Flex key={key + 1} style={{marginTop: "2vw", fontSize: "60%", marginRight: 15}}>
          <span>{new Date(dt * 1000).getHours()}h</span>
          <WeatherIcon dt={dt} style={{fontSize: "2vw"}} sunset={sunset} sunrise={sunrise} id={weather[0].id} />
          <span>{round(temp || 0, 0)}<SmallerSpan top>°C</SmallerSpan></span>
        </Flex>)}
      </Flex>}

    </Flex>
  )
}
