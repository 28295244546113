

export const throttle = (fn: (...args: any[]) => void, delay: number) => {
  let lastCall = 0
  let nextCall: NodeJS.Timeout
  return (...args: any[]) => {
    const now = Date.now()
    if (now - lastCall < delay) {
      clearTimeout(nextCall)
      nextCall = setTimeout(() => {
        lastCall = now
        fn(...args)
      }, delay - (now - lastCall))
    } else {
      lastCall = now
      fn(...args)
    }
  }
}