import { FunctionComponent, h, Fragment } from "preact"
import { useCallback, useMemo } from "preact/hooks"
import { useBooleanState } from "../../../hooks/useBooleanState"
import { onZoneClick } from "../../../libraries/click"
import { ComputerDevice } from "../../../types/Device"
import Button from "../../ui/Button"
import Flex from "../../ui/Flex"
import { Icon } from "../Icon"
import Modal from "../../ui/Modal"
import styles from "../../styles.scss"
import Slab from "./Slab"
import { useEventListener } from "../../../libraries"
import { useApi } from "../../../hooks/useAct"

type IComputerProps = {
  device: ComputerDevice
}

export const Computer: FunctionComponent<IComputerProps> = ({ device }) => {

  const [act, loading] = useApi(device)

  const toggleState = useCallback(() => act(`${device.state ? "off" : "on"}`), [device.state])
  const setVolume = useCallback((direction: "down5" | "down" | "up" | "up5") => act(`volume/${direction}`), [])
  const setSource = useCallback((source: string) => act(`mode/${source}`), [])

  const [panelIsVisible, { on: openPanel, off: hidePanel }] = useBooleanState(false)
  useEventListener("begin-inactive", hidePanel)

  const onActiveClick = useCallback(onZoneClick([[toggleState], [openPanel]], [], [2, 1]), [toggleState])

  return <Slab
    loading={loading}
    disabled={device.reachable === false}
    device={device}
    props={{
      container: {
        className: device.state ? styles.on : "",
        onClick: device.state ? onActiveClick : toggleState,
      },
      labelContainer: {
        style: {
          display: "flex",
          flexDirection: "row",
        },
      }
    }}
    label={(defaultRenderer) => <>
      {defaultRenderer()}
      {device.state && <div style={{
        marginLeft: 10,
        textAlign: "center",
      }}>
        <Icon
          className={styles.on}
          style={{ width: 32, height: 32 }}
          image="remote"
        />
      </div>}
    </>}
  >
    <Modal title="Ampli" show={panelIsVisible} onDismiss={hidePanel}>
      <Flex horizontalAlign="center">
      </Flex>
    </Modal>
  </Slab>
}

export default Computer
