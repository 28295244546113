import { FunctionComponent, h } from "preact"

type ISeparatorProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
}

export const Separator: FunctionComponent<ISeparatorProps> = ({ style, ...props }) => {

  return <div style={{
    width: "100%",
    height: "1px",
    backgroundColor: "rgba(180, 180, 180, .25)",
    ...style as any,
  }} {...props}/>
}

export default Separator
