import { FunctionComponent, h } from "preact"
import { classnames } from "../../libraries"

export type IWeatherIconProps = Omit<h.JSX.HTMLAttributes<HTMLElement>, "id"> & {
  sunrise: number
  sunset: number
  id: number
  dt: number
}

export const WeatherIcon: FunctionComponent<IWeatherIconProps> = ({ sunrise, sunset, id, className, dt, ...props }) => {

  const isDay = sunrise < sunset && dt > sunrise && dt < sunset

  return (
    <i
      className={classnames(className, "owf", `owf-${id}${isDay ? "-d" : "-n"}`)}
      {...props}
    />
  )
}
